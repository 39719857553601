import gsap from "gsap";

export function startCurtainAnimation(onCompleteFunc) {
  gsap
    .timeline({
      onComplete: () => {
        onCompleteFunc();
      },
    })
    .set(".cover-strip", { top: "200vh" })
    .to(".cover-strip", {
      top: "100vh",
      duration: 0.75,
      ease: "Expo.easeInOut",
      stagger: 0.1,
    });
}

// Helper function to get element by ID, check that there's children, and return those children;
export function getChildren(id) {
  const elm = document.getElementById(id);
  if (!elm) return null;
  return elm.children;
}
