import React, { useState, useEffect, Fragment, useMemo } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { twJoin, twMerge } from "tailwind-merge";

import TransitionLink from "components/TransitionLink";

import PumpIcon from "assets/brand/logos/pump.svg";

const navigation = [
  { name: "Services", href: "/services" },
  { name: "Projects", href: "/projects" },
  { name: "Team", href: "/team" },
  { name: "Careers", href: "/careers" },
  { name: "Blog", href: "/blog" },
  { name: "Contact", href: "/contact" },
];

const HamburgerButtonAndSidebar = () => {
  const router = useRouter();
  const [startingRoute] = useState(router.pathname);

  const [open, setOpen] = useState(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const rotationWrapperRef = React.useRef<HTMLSpanElement>(null);
  const line1Ref = React.useRef<HTMLSpanElement>(null);
  const line2Ref = React.useRef<HTMLSpanElement>(null);
  const animationsRef = React.useRef<{ rotation: Animation; line1: Animation; line2: Animation }>();

  // Attach animations on the hamburger elements
  useEffect(() => {
    const rotationWrapper = rotationWrapperRef.current;
    const line1 = line1Ref.current;
    const line2 = line2Ref.current;
    if (!rotationWrapper || !line1 || !line2) return;

    const animationOptions: KeyframeAnimationOptions = {
      duration: 500,
      easing: "ease-in-out",
      fill: "both",
    };

    const rotationAnimation = rotationWrapper.animate(
      [
        { offset: 0, transform: "none" },
        { offset: 0.33, transform: "none" },
        { offset: 0.66, transform: "rotate(90deg)" },
        { offset: 1, transform: "rotate(90deg)" },
      ],
      animationOptions
    );
    const line1Animation = line1.animate(
      [
        { offset: 0, transform: "translateY(6px)" },
        { offset: 0.33, transform: "none" },
        { offset: 0.66, transform: "none" },
        { offset: 1, transform: "translateY(8px)" },
      ],
      animationOptions
    );
    const line2Animation = line2.animate(
      [
        { offset: 0, transform: "translateY(-6px)" },
        { offset: 0.33, transform: "none" },
        { offset: 0.66, transform: "none" },
        { offset: 1, transform: "translateY(-8px)" },
      ],
      animationOptions
    );

    // Animations must not autoplay at mount
    rotationAnimation.pause();
    line1Animation.pause();
    line2Animation.pause();

    animationsRef.current = {
      rotation: rotationAnimation,
      line1: line1Animation,
      line2: line2Animation,
    };

    return () => {
      rotationAnimation.cancel();
      line1Animation.cancel();
      line2Animation.cancel();
    };
  }, []);

  const setOpenAndResetAnimation = (open: boolean) => {
    setOpen(open);

    const animations = animationsRef.current;
    if (!animations) return;

    // Reverse animation when closing the menu
    const playbackRate = open ? 1 : -1;
    // Reset animation starting point
    const currentTime = open ? 0 : -1;

    animations.rotation.currentTime = currentTime;
    animations.line1.currentTime = currentTime;
    animations.line2.currentTime = currentTime;

    animations.rotation.playbackRate = playbackRate;
    animations.line1.playbackRate = playbackRate;
    animations.line2.playbackRate = playbackRate;
  };

  const setOpenAndAnimate = (open: boolean) => {
    setOpenAndResetAnimation(open);

    animationsRef.current?.rotation.play();
    animationsRef.current?.line1.play();
    animationsRef.current?.line2.play();
  };

  // If menu is open and the route changes, close the menu; it doesn't happen
  // automatically unfortunately
  useEffect(() => {
    // The first run of this effect is not a route change, so skip it
    if (router.pathname === startingRoute) return;
    setOpenAndResetAnimation(false);
  }, [router.pathname, startingRoute]);

  useEffect(() => {
    // Close the menu if after a resize the hambruger button is not visible
    // anymore (for example rotating the phone from portrait to landscape and
    // unloking space for the desktop menu instead of the mobile one)
    const handleResize = () => {
      if (buttonRef.current && getComputedStyle(buttonRef.current).display === "none") {
        setOpenAndResetAnimation(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Lines of the button are always white when open, otherwise use "currentColor";
  // also animate the color as it may change opening/closing the menu
  const linesColor = twJoin("transition-colors", open === true ? "bg-white" : "bg-current");

  // The animation of the button introduces some accessibility challenges:
  // 1) we want to keep the button visible as it animates while the menu opens:
  //    solved tuning the z-index of the button, the menu modal and the top bar
  // 2) we want the same button to be able to both open and close the modal:
  //    this is difficult as the button is outside the modal, so HeadlessUI
  //    disables it when the modal is open; we solved it adding a second
  //    transparent button inside the modal, placed in the same position of the
  //    animated but disabled one, so that it makes it look like the original
  //    button can be keyboard focused and clicked. This is why we save size and
  //    position of the button in a var, so it can be reused for the second
  //    clone button.
  const buttonSizeAndPosition = "fixed flex w-12 h-12 p-3 -ml-3 left-8 top-1";

  return (
    <>
      <button
        ref={buttonRef}
        aria-label="Menu"
        className={twMerge(buttonSizeAndPosition, "z-[22] sm:hidden")}
        onClick={() => setOpenAndAnimate(true)}
      >
        {/* Wrapper that hanldes the rotation part of the animation */}
        <span ref={rotationWrapperRef} className="flex w-full h-full relative">
          {/* Lines of the hamburger */}
          <span
            ref={line1Ref}
            className={twMerge(
              "h-0.5 -mt-px w-full flex absolute top-1/2 translate-y-1.5",
              linesColor
            )}
          />
          <span
            ref={line2Ref}
            className={twMerge(
              "h-0.5 -mt-px w-full flex absolute top-1/2 -translate-y-1.5",
              linesColor
            )}
          />
        </span>
      </button>

      {/* Menu modal */}
      <Transition show={open}>
        <Dialog onClose={() => setOpenAndAnimate(false)}>
          <Transition.Child
            enter="transition-transform duration-250 ease-[ease]"
            enterFrom="transform -translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition-transform duration-250 ease-[ease]"
            leaveFrom="transform translate-x-0"
            leaveTo="transform -translate-x-full"
            as={Fragment}
          >
            <Dialog.Panel
              as="nav"
              className="fixed top-0 left-0 h-screen w-[calc(100vw-1rem)] max-w-xs bg-dark z-[21]"
            >
              <ul className="p-0 pt-20">
                {navigation.map((item, index) => (
                  <li
                    key={item.name}
                    className={`before:content-none px-0 py-3 flex items-center mt-0 animate-[fade-in_250ms_ease_both]`}
                    style={{ animationDelay: `${175 + 50 * index}ms` }}
                  >
                    {/* White line on the left to mark current page */}
                    <span
                      className={twJoin(
                        "w-1 h-9 mr-7",
                        router.pathname === item.href && "bg-white"
                      )}
                    />
                    <TransitionLink
                      href={item.href}
                      aria-current={router.pathname === item.href ? "page" : undefined}
                      className={
                        "text-3xl text-white transition-opacity ease-[ease] duration-250 override-hover hover:opacity-75"
                      }
                    >
                      {item.name}
                    </TransitionLink>
                  </li>
                ))}
              </ul>
            </Dialog.Panel>
          </Transition.Child>
          {/* Transparent button that overlaps the hamburger button and allows
              the modal to be closed; added after the other links so it's last
              in the tab order */}
          <button
            aria-label="Close Menu"
            className={twMerge(buttonSizeAndPosition, "z-[23]")}
            onClick={() => setOpenAndAnimate(false)}
          />
        </Dialog>
      </Transition>
    </>
  );
};

interface NavProps {
  variant?: "white-on-transparent" | "white-on-red" | "white-on-black" | "black-on-white";
}

const Nav = ({ variant = "black-on-white" }: NavProps) => {
  const scrollDetectElemRef = React.useRef<HTMLDivElement>(null);
  const [scrolled, setScrolled] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!scrollDetectElemRef.current) return;

    // scrollDetectElem is a 1x1 invisible div at the top of the page, when it
    // doesn't intersect the viewport anymore it means the user scrolled
    const observer = new IntersectionObserver(([entry]) => {
      setScrolled(entry.isIntersecting === false);
    });
    observer.observe(scrollDetectElemRef.current);

    return () => observer.disconnect();
  }, []);

  const { textStyle, backgroundStyle, currentPageStyle } = useMemo(() => {
    // Trick: any variable named className enables Tailwind's IDE support
    let className;
    switch (variant) {
      case "white-on-transparent":
        className = {
          textStyle: "text-white",
          backgroundStyle: "bg-transparent",
          currentPageStyle: "bg-white text-brand-500",
        };
        break;
      case "white-on-red":
        className = {
          textStyle: "text-white",
          backgroundStyle: "bg-brand-500",
          currentPageStyle: "bg-white text-brand-500",
        };
        break;
      case "white-on-black":
        className = {
          textStyle: "text-white",
          backgroundStyle: "bg-black",
          currentPageStyle: "bg-white text-brand-500",
        };
        break;
      case "black-on-white":
        className = {
          textStyle: "text-dark",
          backgroundStyle: "bg-white",
          currentPageStyle: "bg-dark text-white",
        };
        break;
    }
    return className;
  }, [variant]);

  return (
    <header>
      <div ref={scrollDetectElemRef} className="absolute top-0 left-0 w-px h-px invisible" />
      <nav className={twJoin("transition-colors duration-300", textStyle)}>
        {/* This component can't share the same parent as the other nav elements
            bacause of z-index (we want sidebar on top of the other elements,
            and the hamburger on top of the sidebar) */}
        <HamburgerButtonAndSidebar />

        <div className="fixed left-0 top-0 right-0 flex z-20">
          {backgroundStyle !== "bg-transparent" && (
            // Background that appears on scroll, if the variant has it
            <div
              className={twJoin(
                "absolute inset-0 transition-[opacity] duration-300 z-[-1]",
                backgroundStyle,
                scrolled ? "opacity-full" : "opacity-0"
              )}
            />
          )}

          <div
            className={twJoin(
              "flex justify-center items-center transition-[padding] duration-[250ms] py-1 sm:py-3",
              scrolled ? "constrained-nav" : "lg:pt-10 constrained"
            )}
          >
            {/* Logo */}
            <TransitionLink
              href="/"
              passHref
              ariaLabel="Homepage"
              className="flex sm:-ml-2 transition-opacity duration-[250ms] hover:opacity-70 active:opacity-50"
            >
              <PumpIcon className="w-12 h-12 p-2 fill-current" />
            </TransitionLink>

            {/* Right side */}
            <ul className="hidden sm:flex items-center ml-auto p-0 gap-4">
              {navigation.map((item) => {
                // Using startsWith to highlight the section even when in subpages
                // Example: /team/rameet-chawla
                const isCurrentPage = router.pathname.startsWith(item.href);
                return (
                  <li key={item.name} className="before:content-none py-2 px-0 m-0">
                    <TransitionLink
                      href={item.href}
                      aria-current={isCurrentPage ? "page" : undefined}
                      className={twJoin(
                        "p-2 transition-opacity duration-[250ms] hover:opacity-70 active:opacity-50",
                        isCurrentPage && currentPageStyle
                      )}
                    >
                      {item.name}
                    </TransitionLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Nav;
