import { ComponentProps, ReactNode } from "react";

import Footer from "components/Footer";
import Nav from "components/Nav";

interface DefaultLayoutProps {
  navVariant?: ComponentProps<typeof Nav>["variant"];
  children: ReactNode;
  hideFooter?: boolean;
}

const DefaultLayout = ({
  navVariant = "black-on-white",
  children,
  hideFooter = false,
}: DefaultLayoutProps) => {
  return (
    <>
      <Nav variant={navVariant} />
      <main>{children}</main>
      {!hideFooter && <Footer />}
    </>
  );
};

export default DefaultLayout;
