import { useMemo } from "react";

import { useRouter } from "next/router";
import { twJoin, twMerge } from "tailwind-merge";

import TransitionLink from "components/TransitionLink";

import LeafIcon from "assets/brand/footer/leaf-icon.svg";

type Link = { label: string; url: string; external?: boolean };

const links_left: Link[] = [
  { label: "Perks & Benefits", url: "/careers/perks-and-benefits" },
  { label: "Life at Fueled", url: "/careers/culture" },
  { label: "Values", url: "/careers/values" },
  { label: "Design", url: "/iphone-app-design" },
  { label: "Growth", url: "/product-led-growth" },
  { label: "Android", url: "/android-app-development" },
  { label: "iOS", url: "/ios-app-development" },
];

const links_right: Link[] = [
  {
    label: "Digital Transformation",
    url: "/digital-transformation-services-nyc",
  },
  { label: "Web Development", url: "/web-development-agency" },
  { label: "Smart Contract", url: "/smart-contract-developer" },
  { label: "Branding", url: "/branding-agency" },
  { label: "dApps", url: "https://anchordapps.com", external: true },
  { label: "Web3", url: "/web3-developers" },
  { label: "DeFi", url: "/defi-development-company" },
];

const navHoverClassName =
  "transition duration-500 ease-in-out no-underline hover:opacity-75 hover:text-white hover:underline py-0.5 xl:text-lg";

const year = new Date().getFullYear();

const Nav = () => {
  const router = useRouter();

  const isContactPage = useMemo(() => {
    const { pathname } = router;

    return pathname.startsWith("/contact");
  }, [router]);
  const isHomePage = useMemo(() => {
    const { pathname } = router;

    return pathname === "/";
  }, [router]);

  return (
    <footer
      className={twJoin(
        "relative z-10 mt-auto block w-full text-white md:flex",
        isHomePage && "h-screen h-svh"
      )}
    >
      {/* Left */}
      <div
        className={twJoin(
          "flex sm:h-1/2 w-full flex-col items-center justify-between space-y-8 bg-brand-500",
          "transition-colors hover:bg-brand-700",
          "md:h-auto md:w-1/2 md:justify-center lg:w-3/5 lg:px-4",
          isContactPage && "justify-center py-16"
        )}
      >
        {!isContactPage ? (
          <TransitionLink
            href="/contact"
            passHref
            className="group h1 flex h-full w-full items-center justify-center py-[25%]"
          >
            <span className="link-arrow-right group-hover:link-arrow-right-active">
              Let&apos;s Chat
            </span>
          </TransitionLink>
        ) : (
          <>
            <a
              href="mailto:hello@fueled.com"
              className="h2 hover:text-white hover:underline hover:opacity-75"
            >
              HELLO@FUELED.COM
            </a>
            <a
              href="tel:+1-212-763-7726"
              className="h2 override-hover hover:text-white hover:underline hover:opacity-75"
            >
              (212) 763-7726
            </a>
          </>
        )}
      </div>

      {/* Right */}
      <div
        className={twJoin(
          "flex min-h-[50%] w-full flex-col justify-between bg-brand-400 px-6 py-12",
          "md:h-auto md:min-h-min md:w-1/2 md:px-8 md:pt-24 lg:w-2/5 lg:px-12 sm:pt-28"
        )}
      >
        <div className="grid max-w-2xl grid-cols-2 tall:mb-8">
          <div className="flex flex-col">
            {links_left.map((link, i) => (
              <TransitionLink
                key={link.label}
                href={link.url}
                passHref
                // limit to 4 links for mobile landscape
                className={twMerge(navHoverClassName, i > 4 && "hidden tall:inline-block")}
              >
                {link.label}
              </TransitionLink>
            ))}
          </div>
          <div className="flex flex-col">
            {links_right.map((link, i) =>
              link.external ? (
                <a
                  href={link.url}
                  key={link.label}
                  // limit to 4 links for mobile landscape
                  className={twMerge(navHoverClassName, i > 4 && "hidden tall:inline-block")}
                >
                  {link.label}
                </a>
              ) : (
                <TransitionLink
                  key={link.label}
                  href={link.url}
                  passHref
                  // limit to 4 links for mobile landscape
                  className={twMerge(navHoverClassName, i > 4 && "hidden tall:inline-block")}
                >
                  {link.label}
                </TransitionLink>
              )
            )}
          </div>
        </div>
        <div className="pt-6 text-center sm:text-left">
          <TransitionLink
            href="/fueled-digital-media-llc"
            passHref
            className="h6 max-w-none transition-opacity hover:text-white hover:opacity-75"
          >
            &copy; {year} | Established 2009
          </TransitionLink>

          <div className="flex flex-col pt-1 body-xs gap-1">
            <div className="flex flex-col gap-2">
              <a
                href="mailto:hello@fueled.com"
                className="underline opacity-75 transition-opacity hover:text-white hover:opacity-100"
              >
                hello@fueled.com
              </a>
              {/* Hide address in mobile landscape to save vertical space */}
              <address className="not-italic opacity-75 tall:block">
                430 W 14th St, New York, NY 10014
              </address>
            </div>
            <div className="tall:mt-5 flex items-center gap-2 justify-center sm:justify-start">
              <TransitionLink
                href="/privacy-policy"
                passHref
                className="underline opacity-75 transition-opacity hover:text-white hover:opacity-100"
              >
                Privacy Policy
              </TransitionLink>
              <a
                href="https://www.notion.so/Fueled-s-Carbon-Neutral-Commitment-631d9f6783564a4b92d68c16b10fad80"
                rel="noopener noreferrer"
                target="_blank"
                className="inline-flex items-center space-x-1 underline opacity-75 hover:text-white hover:opacity-100"
              >
                <LeafIcon aria-hidden />
                <span>Fueled is carbon neutral</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Nav;
