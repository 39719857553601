// the trailing slash means there's more to the URL e.g tean member page / case study
export const REWRITES_URLS = [
  "/blog",
  "/blog/",
  "/projects/",
  "/product-led-growth",
  "/digital-transformation-services-nyc",
  "/ios-app-development",
  "/iphone-app-design",
  "/android-app-development",
  "/web-development-agency",
] as const;
