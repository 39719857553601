import { CSSProperties, MouseEventHandler, PropsWithChildren } from "react";

import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";

import Analytics from "services/analytics";
import { REWRITES_URLS } from "services/rewrites";

import { startCurtainAnimation } from "utilities/animation";

const isRewriteURL = (targetUrl: LinkProps["href"]) => {
  if (typeof targetUrl === "string") {
    return REWRITES_URLS.some((reWriteUrl) => targetUrl.includes(reWriteUrl));
  }
  return false;
};

const TransitionLink = ({
  className,
  id,
  ariaLabel,
  href,
  children,
  ...props
}: PropsWithChildren<
  LinkProps & { className?: string; ariaLabel?: string; id?: string; style?: CSSProperties }
>) => {
  const router = useRouter();

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();

    // next.js rewrite URLS are not handled by next-router,
    // so we cannot track route navigation,
    // this also breaks the page transitons so we do not animate between these proxy pages. This is only temporary
    if (isRewriteURL(href)) {
      const paths = typeof href === "string" ? href.split("/") : [];
      Analytics.trackPageView(`/${paths[1]}`, {
        slug: paths[2],
      });
      router.push(href);
      return;
    }
    // start transition, on complete redirect.
    startCurtainAnimation(() => router.push(href));
  };

  return (
    <Link
      href={href}
      passHref
      {...props}
      onClick={handleClick}
      className={className}
      aria-label={ariaLabel}
      id={id}
    >
      {children}
    </Link>
  );
};

export default TransitionLink;
